.Menu {
  display: flex;
  flex-direction: row;
}

.Header {
  width: 100%;
  position: relative;
  text-align: center;
  background: #00acc1;
  color: white;
}

.Flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.Waves {
  position: relative;
  width: 100%;
  height: 10vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 50px;
  max-height: 150px;
}

.NavigationItemsDesktop {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}

.HamburgerMenu {
  margin-right: 2rem;
  margin-top: 1rem;
  cursor: pointer;
}

.NavigationItemsMobile {
  list-style: none;
  z-index: 100;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: -100px;
  color: white;
  background-color: rgb(0, 172, 193, 0.8);
  border-radius: 5px;
  height: 50vh;
  padding: 2rem;
  animation: slide 0.5s forwards;
}

/* Animation */

.Parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.Parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 10s;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .Waves {
    height: 40px;
  }

  .Content {
    height: 30vh;
  }

  .NavigationItemsDesktop {
    display: none;
    color: white;
  }
}

@media (min-width: 769px) {
  .HamburgerMenu {
    display: none;
    margin-right: 2rem;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes slide {
  100% {
    right: 0px;
  }
}
